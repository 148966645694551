<template>
  <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link :to="{ name: 'Archives' }">Archives</router-link> |
    <router-link :to="{ name: 'About' }">About Me</router-link>
  </div>
  <router-view v-slot="{ Component }">
    <transition name="route" mode="out-in">
      <component :is="Component"></component>
    </transition>
  </router-view>
</template>

<style>
body {
  background-image: url("./assets/background-compress.jpg");
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
  padding: 10px;
  border-radius: 40px;
}
#nav a.router-link-exact-active {
  color: #2c3e50;
  background: #acbd8c96;
}

/* route transitions */
.route-enter-from {
  opacity: 0;
  transform: translateY(-60px);
}
.route-enter-active {
  transition: all 0.3s ease-out;
}
.route-leave-active {
  transition: all 0.2s ease-in;
}
.route-leave-to {
  opacity: 0;
}
</style>
