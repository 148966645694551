<template>
  <div class="about">
    <h1>About Me</h1>
    <!-- <div class="block">
      <h2>Contact Information</h2>
      <h3>Email</h3>
      <p><a href="mailto:ycwang.cs@gmail.com">ycwang.cs@gmail.com</a></p>
    </div> -->
    <transition name="blockFade" mode="out-in" appear>
    <div class="block">
      <h2>Education</h2>
      <h3>2020.09 - Present</h3>
      <h4>B.S. in
        <a href="http://www.cs.nycu.edu.tw">Computer Science</a>
      </h4>
      <p>
        <a href="http://www.nycu.edu.tw"
          >National Yang Ming Chiao Tung University</a
        >, Taiwan
      </p>
    </div>
    </transition>
    <transition name="blockFade" mode="out-in" appear>
    <div class="block">
      <h2>Research Experience</h2>
      <h3>2020.09 - 2021.07</h3>
      <h4>
        An Autonomous Route-Planning System for Emergency
        Vehicles Based on Graph Theory
      </h4>
      <p>
        Advised by Prof. Chiuyuan Chen
      </p>
    </div>
    </transition>
    <transition name="blockFade" mode="out-in" appear>
    <div class="block">
      <h2>Course Projects</h2>
      <h3>2020.09 - 2021.01</h3>
      <h4>Simple Bulletin Board System (BBS) Server</h4>
      <p>
        Course: Introduction to Network Programming
      </p>
      <h3>2021.02 - 2021.06</h3>
      <h4>Multifaceted Item Selection Method for Fixed-Form Assessment</h4>
      <p>
        Course: Genetic Algorithm and Applications in Management Science
      </p>
    </div>
    </transition>
    <transition name="blockFade" mode="out-in" appear>
    <div class="block">
      <h2>Honors and Awards</h2>
      <h3>2020.09 - 2021.01</h3>
      <h4>Academic Achievement Award</h4>
      <p>
        Sponsored by
        <a href="http://www.nycu.edu.tw"
          >National Yang Ming Chiao Tung University</a
        >, Taiwan
      </p>
      <h3>2021.02 - 2021.06</h3>
      <h4>Academic Achievement Award</h4>
      <p>
        Sponsored by
        <a href="http://www.nycu.edu.tw"
          >National Yang Ming Chiao Tung University</a
        >, Taiwan
      </p>
    </div>
    </transition>
    <transition name="blockFade" mode="out-in" appear>
    <div class="block">
      <h2>Key Skills</h2>
      <h3>Front-end Development</h3>
      <p>
        HTML, CSS, JavaScript, VueJS, ReactJS
      </p>
      <h3>Backend Development</h3>
      <p>
        C#, Python, MS SQL, MySQL, SQLite, Flask
      </p>
      <h3>UI/UX Design</h3>
      <p>
        Figma
      </p>
      <h3>Other</h3>
      <p>
        Docker, Azure DevOps (TFS), C++, LaTeX, Markdown
      </p>
    </div>
    </transition>
  </div>
</template>
<style>
.about .block {
  background: #8d8a819f;
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 10px;
  margin: 10px auto;
  max-width: 1200px;
  color: #302b23;
}
.about .block a {
  color: #302b23;
}
/* block fade transitions */
.about .blockFade-enter-from {
  opacity: 0;
}
.about .blockFade-enter-active {
  transition: all 1s ease-out;
}
.about .blockFade-leave-active {
  transition: all 0.4s ease-in;
}
.about .blockFade-leave-to {
  opacity: 0;
}
</style>
