<template>
  <h2>Page Not Found.</h2>
  <button @click="redirect">Redirect</button>
  <button @click="back">Go Back</button>
</template>
<script>
export default {
  methods: {
    redirect() {
      this.$router.push({ name: "Home" });
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style>
button {
  margin: 0 10px;
  padding: 10px;
  border: none;
  border-radous: 4px;
}
</style>
