<template>
  <div class="archive">
    <transition name="blockFade" mode="out-in" appear>
      <div v-if="isLoading">
        <p>Loading...</p>
      </div>
      <div v-else>
        <h1>{{ archive.title }}</h1>
        <p>Published at: {{ archive.datePublish }}</p>
        <div class="content">Loading content...</div>
      </div>
    </transition>
  </div>
</template>
<script>
import db from "../firebaseInit";
import { collection, onSnapshot, query, where } from "firebase/firestore";
export default {
  name: "view-archive",
  data() {
    return {
      isLoading: false,
      archive: {},
    };
  },
  // watch: {
  //   $route: "fetchData",
  // },
  mounted() {
    this.fetchDataRealtime();
  },
  methods: {
    fetchDataRealtime() {
      this.isLoading = true;
      const colRef = collection(db, "archives");
      const q = query(colRef, where("slug", "==", this.$route.params.slug));
      const q2 = query(q, where("published", "==", true));
      onSnapshot(q2, (snapshot) => {
        snapshot.docs.forEach((doc) => {
          this.archive = {
            ...doc.data(),
            id: doc.id,
            datePublish: doc.data().timePublish.toDate().toDateString(),
          };
          document.querySelector(".content").innerHTML = this.archive.content;
        });
      });
      this.isLoading = false;
    },
  },
};
</script>
<style>
.content {
  background: #8d8a81d0;
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 10px;
  margin: 10px auto;
  max-width: 1200px;
  color: #302b23;
  text-align: left;
}
.code {
  background: #282c34;
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 10px;
  margin: 10px auto;
  max-width: 600px;
  color: #b4b4b4;
  text-align: left;
  overflow: auto;
}
.archive .blockFade-enter-from {
  opacity: 0;
}
.archive .blockFade-enter-active {
  transition: all 1.5s ease-out;
}
.archive .blockFade-leave-active {
  transition: all 0.4s ease-in;
}
.archive .blockFade-leave-to {
  opacity: 0;
}
</style>
