import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Archives from "../views/archives/Archives.vue";
import Archive from "../views/archives/Archive.vue";
import About from "../views/About.vue";
import NotFound from "../views/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/archives",
    name: "Archives",
    component: Archives,
  },
  {
    path: "/archives/:slug",
    name: "Archive",
    component: Archive,
    props: true,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
    component: About,
  },
  // catchall 404
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
