import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// const firebaseApp = firebase.initializeApp(firebaseConfig);
const firebaseConfig = {
  apiKey: "AIzaSyArEsAGgnzOxLlAnMbTjh65rN1AcAo2NxY",
  authDomain: "ycwang-org.firebaseapp.com",
  projectId: "ycwang-org",
  storageBucket: "ycwang-org.appspot.com",
  messagingSenderId: "864408080350",
  appId: "1:864408080350:web:3b442d9ded7b6aa2a8574f",
  measurementId: "G-ZEBGBLRXW7",
};
initializeApp(firebaseConfig);
const db = getFirestore();
// const auth = firebaseApp.auth();
export default db;
// export { auth };
