<template>
  <div class="home">
    <h1>Welcome to <i>ycwang.org</i>.</h1>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
