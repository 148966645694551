<template>
  <div class="archives">
    <h1>Archive</h1>
    <!-- <h3>Under Construction</h3> -->
    <transition name="blockFade" mode="out-in" appear>
      <div v-if="isLoading">
        <div>
          <p>Loading...</p>
        </div>
      </div>
      <div v-else>
        <div v-for="archive in archives" :key="archive.slug" class="_archive">
          <router-link
            :to="{ name: 'Archive', params: { slug: archive.slug } }"
          >
            <h2>{{ archive.title }}</h2>
            <!-- <p>{{ archive.summary }}</p> -->
            <p id="datePublish">Published at {{ archive.datePublish }}</p>
          </router-link>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import db from "../firebaseInit";
import {
  collection,
  onSnapshot,
  getDocs,
  query,
  where,
} from "firebase/firestore";
export default {
  data() {
    return {
      isLoading: false,
      archives: [],
    };
  },
  mounted() {
    this.getData();
  },
  // firestore() {
  //   return {
  //     archives: db.collection("archives").orderBy("title"),
  //   };
  // },
  methods: {
    getData() {
      this.isLoading = true;
      const colRef = collection(db, "archives");
      const q = query(colRef, where("published", "==", true));
      getDocs(q)
        .then((snapshot) => {
          snapshot.docs.forEach((doc) => {
            this.archives.push({
              ...doc.data(),
              id: doc.id,
              datePublish: doc.data().timePublish.toDate().toDateString(),
            });
          });
          // console.log("archives");
          // console.log(this.archives);
        })
        .catch((err) => {
          console.log(err.message);
        })
        .finally(() => (this.isLoading = false));
    },
    getDataRealtime() {
      this.isLoading = true;
      const colRef = collection(db, "archives");
      const q = query(colRef, where("published", "==", true));
      onSnapshot(q, (snapshot) => {
        snapshot.docs.forEach((doc) => {
          this.archives.push({
            ...doc.data(),
            id: doc.id,
            datePublish: doc.data().timePublish.toDate().toDateString(),
          });
        });
      });
      this.isLoading = false;
    },
  },
};
</script>
<style>
._archive {
  background: #8d8a815b;
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 10px;
  margin: 10px auto;
  max-width: 600px;
  cursor: pointer;
  color: #302b23;
  text-align: left;
}
._archive #datePublish {
  font-size: 12px;
  text-align: right;
}
._archive:hover {
  background: #8d8a81d0;
}
._archive a {
  color: #302b23;
  text-decoration: none;
}
/* block fade transitions */
.archives .blockFade-enter-from {
  opacity: 0;
}
.archives .blockFade-enter-active {
  transition: all 0.4s ease-out;
}
.archives .blockFade-leave-active {
  transition: all 0.2s ease-in;
}
.archives .blockFade-leave-to {
  opacity: 0;
}
</style>
